import React from "react";

const About = () => {
    return (
        <div>
            
        </div>
    );
}

export default About;