import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { FaUser, FaLock } from "react-icons/fa";
import { useUserContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";


const Register = () => {

    const { register } = useUserContext();
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Submitted");
        const email = e.target.formEmail.value;
        const password = e.target.formPassword.value;
        const firstName = e.target.formFirstname.value;
        const lastName = e.target.formLastname.value;
        const typeAccount = e.target.formTypeAccount.value;

        if (
            email.trim() === "" ||
            password.trim() === "" ||
            firstName.trim() === "" ||
            lastName.trim() === "" ||
            typeAccount.trim() === ""
        ) {
            alert("Please fill all the fields");
            return;
        }

        console.log(email + " " + password + " " + firstName + " " + lastName + " " + typeAccount);
        
        try {
            await register(email, password, firstName, lastName, typeAccount);
            navigate("/login"); // Redirect to login on successful registration
          } catch (error) {
            console.error("Registration failed:", error);
            alert("Registration failed. Please try again.");
          }
        
    }

    return (
        <Container
            className="d-flex justify-content-center align-items-center vh-100"
            style={{ backgroundColor: "#f8f9fa" }}
        >
            <Card style={{ width: "400px" }} className="shadow-sm border-0">
                <Card.Body>
                    <h3 className="text-center mb-4" style={{ fontWeight: "bold" }}>
                        Register
                    </h3>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formEmail" className="mb-3">
                            <Form.Label>
                                <FaUser className="me-2" />
                                Email
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your email"
                            />
                        </Form.Group>

                        <Form.Group controlId="formPassword" className="mb-3">
                            <Form.Label>
                                <FaLock className="me-2" />
                                Password
                            </Form.Label>
                            <Form.Control type="password" placeholder="Enter your password" />
                        </Form.Group>

                        <Form.Group controlId="formFirstname" className="mb-3">
                            <Form.Label>
                                <FaUser className="me-2" />
                                FirstName
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your firstname"
                            />
                        </Form.Group>

                        <Form.Group controlId="formLastname" className="mb-3">
                            <Form.Label>
                                <FaUser className="me-2" />
                                LastName
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your lastname"
                            />
                        </Form.Group>

                        <Form.Group controlId="formTypeAccount" className="mb-3">
                            <Form.Label>
                                <FaUser className="me-2" />
                                Type Account
                            </Form.Label>
                            <Form.Select
                                aria-label="Default select example"
                            >
                                <option value="Standard"> Chirias</option>
                                <option value="Owner">Proprietar</option>
                                <option value="Agent">Agentie Imobiliara</option>
                            </Form.Select>
                        </Form.Group>


                        <div className="d-grid">
                            <Button type="submit" className="rounded-pill btn btn-odaia">
                                Sign up
                            </Button>
                        </div>
                    </Form>

                    <div className="text-center mt-4">
                        <div style={{ color: "#3D5E41" }} className="text-decoration-none">
                            Already have an account?
                        </div>
                    </div>
                    <div className="text-center mt-2  ">
                        <a href="/login" className="text-decoration-none">
                            <strong style={{ color: "#3D5E41" }}>Log in</strong>
                        </a>
                    </div>
                </Card.Body>
            </Card>
        </Container >
    );
}

export default Register;