import React from "react";

import { Button } from "react-bootstrap";

import { FaHeart } from "react-icons/fa";

const FavoritesButton = () => {
    return (
        <a href="/favorites" className="text-decoration-none">
            <Button variant="success" className="rounded-pill d-flex align-items-center px-4" style={{ width: '140px', margin: '4px' }}>
                <FaHeart className="me-2" /> Favorites
            </Button>
        </a>
    );
}

export default FavoritesButton;