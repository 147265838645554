import React from "react";


const Favorites = () => {
    return (
        
        <>
            Favorites
        </>
    );
    }

export default Favorites;