import React from "react";
import { useParams } from "react-router-dom";
import {Col,Card} from "react-bootstrap"
const Listing = ({listing}) => {

    const listing_id = Number(useParams().id);

    
    return (
        <Col xs={12} md={6} lg={3} key={listing_id} className="mb-4">
          <Card className="p-3">
            <img className="mb-3" src={listing?.images[0]} style={{ height: '10em', backgroundColor: '#e0e0e0' }}></img>
            <Card.Text>{listing.description}</Card.Text>
          </Card>
        </Col>
    );

}

export default Listing;