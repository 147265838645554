import React from "react";

const Post = () => {
    return (
        
        <>
            Post
        </>
    );
    }

export default Post;