import { useContext, createContext, useState } from "react";

const ListingsContext = createContext();

const ListingsProvider = (props) => {


        const [listings, setListings] = useState([
            {
                id: 1,
                title: "Listing 1",
                description: "This is the description for listing 1.",
                price: 1000,
                maximumPeople: 4,
                nrRooms: 3,
                nrBedrooms: 2,
                nrBathrooms: 2,
                nrKitchens: 1,
                nrLivingrooms: 1,
                floor: 1,
                yearOfConstruction: 2000,
                surface: 100,
                compartmenting: "OPEN_SPACE",
                perks: [
                    "PARKING",
                    "BALCONY",
                    "GARDEN"
                ],
                preferences: [
                    "PET_FRIENDLY",
                    "SMOKING_ALLOWED"
                ],
                images: [
                    "https://images.unsplash.com/photo-1502672260266-1c1ef2d93688",
                    "https://images.unsplash.com/photo-1502672260266-1c1ef2d93688",
                    "https://images.unsplash.com/photo-1502672260266-1c1ef2d93688"
                ]
            },
            {
                id: 2,
                title: "Listing 2",
                description: "This is the description for listing 2.",
                price: 2000,
                maximumPeople: 6,
                nrRooms: 4,
                nrBedrooms: 3,
                nrBathrooms: 2,
                nrKitchens: 1,
                nrLivingrooms: 1,
                floor: 2,
                yearOfConstruction: 2005,
                surface: 120,
                compartmenting: "SEMI_DETACHED",
                perks: [
                    "PARKING",
                    "BALCONY",
                    "GARDEN"
                ],
                preferences: [
                    "PET_FRIENDLY",
                    "SMOKING_ALLOWED"
                ],
                images: [
                    `${process.env.PUBLIC_URL}/listing2.jpeg`
                ]
            },
            {
                id: 3,
                title: "Listing 3",
                description: "This is the description for listing 3.",
                price: 3000,
                maximumPeople: 8,
                nrRooms: 5,
                nrBedrooms: 4,
                nrBathrooms: 2,
                nrKitchens: 1,
                nrLivingrooms: 1,
                floor: 3,
                yearOfConstruction: 2010,
                surface: 150,
                compartmenting: "DETACHED",
                perks: [
                    "PARKING",
                    "BALCONY",
                    "GARDEN"
                ],
                preferences: [
                    "PET_FRIENDLY",
                    "SMOKING_ALLOWED"
                ],
                images: [
                    `${process.env.PUBLIC_URL}/listing3.jpeg`
                ]
            },
            
            {
                id: 4,
                title: "Listing 4",
                description: "This is the description for listing 4.",
                price: 4000,
                maximumPeople: 10,
                nrRooms: 6,
                nrBedrooms: 5,
                nrBathrooms: 2,
                nrKitchens: 1,
                nrLivingrooms: 1,
                floor: 4,
                yearOfConstruction: 2015,
                surface: 200,
                compartmenting: "DETACHED",
                perks: [
                    "PARKING",
                    "BALCONY",
                    "GARDEN"
                ],
                preferences: [
                    "PET_FRIENDLY",
                    "SMOKING_ALLOWED"
                ], 
                images: [
                    `${process.env.PUBLIC_URL}/listing4.jpeg`
                ]
            },
            {
                id: 5,
                title: "Listing 5",
                description: "This is the description for listing 5.",
                price: 4000,
                maximumPeople: 10,
                nrRooms: 6,
                nrBedrooms: 5,
                nrBathrooms: 2,
                nrKitchens: 1,
                nrLivingrooms: 1,
                floor: 4,
                yearOfConstruction: 2015,
                surface: 200,
                compartmenting: "DETACHED",
                perks: [
                    "PARKING",
                    "BALCONY",
                    "GARDEN"
                ],
                preferences: [
                    "PET_FRIENDLY",
                    "SMOKING_ALLOWED"
                ], 
                images: [
                    `${process.env.PUBLIC_URL}/listing5.jpg`
                ]
            },
            {
                id: 6,
                title: "Listing 6",
                description: "This is the description for listing 6.",
                price: 4000,
                maximumPeople: 10,
                nrRooms: 6,
                nrBedrooms: 5,
                nrBathrooms: 2,
                nrKitchens: 1,
                nrLivingrooms: 1,
                floor: 4,
                yearOfConstruction: 2015,
                surface: 200,
                compartmenting: "DETACHED",
                perks: [
                    "PARKING",
                    "BALCONY",
                    "GARDEN"
                ],
                preferences: [
                    "PET_FRIENDLY",
                    "SMOKING_ALLOWED"
                ], 
                images: [
                    `${process.env.PUBLIC_URL}/listing6.jpg`
                ]
            },
            {
                id: 7,
                title: "Listing 7",
                description: "This is the description for listing 7.",
                price: 4000,
                maximumPeople: 10,
                nrRooms: 6,
                nrBedrooms: 5,
                nrBathrooms: 2,
                nrKitchens: 1,
                nrLivingrooms: 1,
                floor: 4,
                yearOfConstruction: 2015,
                surface: 200,
                compartmenting: "DETACHED",
                perks: [
                    "PARKING",
                    "BALCONY",
                    "GARDEN"
                ],
                preferences: [
                    "PET_FRIENDLY",
                    "SMOKING_ALLOWED"
                ], 
                images: [
                    `${process.env.PUBLIC_URL}/listing7.jpg`
                ]
            }
        ]);

    
        const addListing = (listing) => {
            setListings([...listings, listing]);
        }
    
        return (
            <ListingsContext.Provider value={{ listings, setListings, addListing }}>
                {props.children}
            </ListingsContext.Provider>
        );
    }

export const useListingsContext = () => {
    return useContext(ListingsContext);
}

export default ListingsProvider;