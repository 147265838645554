import React from "react";

import { Card, Badge, Row, Col } from "react-bootstrap";

import { FaStar } from "react-icons/fa"; // Import star icon

import './ComponentStyles/OdaiaStyles.css';

import NestedPhotoCarousel from './NestedPhotoCarousel.js';

const TopMatch = ({ listing }) => (
    <Card className="listing-card p-3">
        <Row>
            {/* Left: Nested Photo Carousel */}
            <Col md={6}>
                <NestedPhotoCarousel photos={listing.photos} />
            </Col>

            {/* Right: Listing Details */}
            <Col md={6}>
                <Card.Body>
                    <Card.Title>{listing.title}</Card.Title>
                    <Card.Text>{listing.description}</Card.Text>
                    <ul>
                        {listing.features.map((feature, idx) => (
                            <li key={idx}>{feature}</li>
                        ))}
                    </ul>
                </Card.Body>
            </Col>
        </Row>
    </Card>
);


export default TopMatch;