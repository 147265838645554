import { createContext, useState, useContext } from "react";

const PageContext = createContext();

const PageProvider = (props) => {
    
        const [sw, setSwitch] = useState("home");
    
        return (
            <PageContext.Provider value={{ sw, setSwitch }}>
                {props.children}
            </PageContext.Provider>
        );
    
    }

export const usePageContext = () => {
    return useContext(PageContext);
}

export default PageProvider;