import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { useUserContext } from "../context/UserContext";
import "./../styles/header.css";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";


const Header = () => {
    const { user, logout } = useUserContext();

    const navigate = useNavigate();

    const handleLogout = (e) => {
        e.preventDefault();
        logout(e);
        navigate("/");
    }

    return (
        <>
            <Navbar sticky="top" expand="sm" style ={{backgroundColor:"#94fbab"}}>
                <Container>
                    {/* Left-aligned logo */}
                    <Navbar.Brand href="/" className="d-inline-block align-center">
                        <img
                            alt=""
                            src="./../odaia.svg"
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                        />{' '}
                        Odaia
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />

                    {/* Right-aligned navigation links */}
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav className="ms-auto">
                            {

                                user.loggedIn ? (
                                    <>
                                        <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                                        <Nav.Link href="/profile">Profile</Nav.Link>
                                    </>
                                ) : (
                                    <>
                                        <Nav.Link href="/login">Login</Nav.Link>
                                        <Nav.Link href="/register">Register</Nav.Link>
                                    </>
                                )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default Header;
