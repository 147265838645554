import React, { useRef, useState } from 'react';

import { Carousel, Card, Badge, Row, Col } from 'react-bootstrap';

import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

import './ComponentStyles/OdaiaStyles.css';

import TopMatch from './TopMatch.js';

const BestMatchCarousel = () => {
    const carouselRef = useRef(null);

    const handlePrev = () => {
        if (carouselRef.current) carouselRef.current.prev();
    };

    const handleNext = () => {
        if (carouselRef.current) carouselRef.current.next();
    };

    const listings = [
        {
            title: "Maria Ionescu",
            description: "Footbal player and animal lover",
            photos: [
                `${process.env.PUBLIC_URL}/person3.jpg`,
            ],
            features: ["Friendly", "Clean", "Sport-lover"],
        },
        {
            title: "Ion Popescu",
            description: "Engineer with 2 lovely dogs",
            photos: [
                `${process.env.PUBLIC_URL}/person1.jpg`,
            ],
            features: ["Animal-lover", "Geek", "Workaholic"],
        },
    ];

    return (
        <div style={{ position: 'relative', padding: '0 60px', marginBottom: '2rem' }}>
            <Carousel indicators={false} controls={false} className="main-carousel" ref={carouselRef}>
                {listings.map((listing, index) => (
                    <Carousel.Item key={index}>
                        <Row className="justify-content-center">
                            <Col xs={12}>
                                <TopMatch listing={listing} />
                            </Col>
                        </Row>
                    </Carousel.Item>
                ))}
            </Carousel>

            {/* Carousel Controls */}
            <div className="carousel-controls">
                <span className="carousel-control-prev" role="button" onClick={handlePrev}>
                    <FaArrowLeft style={{ fontSize: '1.5rem', color: '#333', position: 'absolute', left: '1em' }} />
                </span>
                <span className="carousel-control-next" role="button" onClick={handleNext}>
                    <FaArrowRight style={{ fontSize: '1.5rem', color: '#333', position: 'absolute', right: '1em' }} />
                </span>
            </div>
        </div>
    );
};

export default BestMatchCarousel;
