import React from "react";
import { useParams } from "react-router-dom";
import {Col,Card} from "react-bootstrap"
const FeaturedListing = () => {

    const listing_id = Number(useParams().id);

    return (
        <Col xs={12} className="mb-4">
            <Card className="p-3">
              <img className="mb-3" src={`${process.env.PUBLIC_URL}/listing2.jpeg`} style={{ height: '200px', backgroundColor: '#e0e0e0' }}></img>
              <Card.Text>Featured Apartment Description...</Card.Text>
            </Card>
          </Col>
    );
}

export default FeaturedListing;