import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home/Home';
import About from './components/About';
import Listings from './components/Listings';
import Listing from './components/Listing';
import ListingDetails from './components/ListingDetails';
import Dashboard from './components/Dashboard';
import Header from './components/Header';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Users from './components/Users';
import Login from './components/Login';
import Register from './components/Register';
import Post from './components/Post';
import Favorites from './components/Favorites';
import Messages from './components/Messages';
import Profile from './components/Profile';
import { useState,useEffect } from 'react';
import { API_POINT } from './components/apiPoints/ApiPoints';

function App() {
  document.title = 'Odaia';
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch(`${API_POINT}/users/1`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          setUserInfo(data);
        } else {
          console.error("Failed to fetch user info:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, []);
  console.log(userInfo);

  if (loading || !userInfo || userInfo.password !== "p") {
    return(<>
    <div>
      Wait for the second pitch for the full website!
    </div>
    </>);
  }

  return (
    <>
      <Container className='container-fluid ' >
        <Row>
          <Col>
            <Router>
                  <Header />
              <Routes>
                <Route path="/" element={<Listings />} />
                <Route path="/listing/:id" element={<ListingDetails />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/users" element={<Users />} />
                <Route path="/user" element={<Users />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/about" element={<About />} />
                <Route path="/dashboard" element={<Dashboard />} />

                <Route path='/post' element={<Post />} />
                <Route path='/favorites' element={<Favorites />} />
                <Route path='/messages' element={<Messages />} />
              </Routes>
            </Router>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default App;
