import React from "react";

import { Button } from "react-bootstrap";

import { FaPlus } from "react-icons/fa";

const AddListingButton = () => {
    return (
        <a href="/post" className="text-decoration-none">
            <Button variant="success" className="rounded-pill d-flex align-items-center px-4" style={{ width: '100px', margin: '4px' }}>
                <FaPlus className="me-2" /> Post
            </Button>
        </a>
    );
}

export default AddListingButton;