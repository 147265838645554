import React from "react";
import { Col, Card } from "react-bootstrap";

const Person = () => {
    // Hardcoded listings data
    const listings = [
        
            {
                id: 9,
                title: "Mike Pence",
                description: "Programmer with a passion for cats and cooking.",
                images: [
                    `${process.env.PUBLIC_URL}/person6 (1).jpg`
                ],
                perks: ["Homey", "Nice", "Talkative"],
            },
            {
                id: 10,
                title: "Taylor Beth",
                description: "Programmer with a passion for cats and cooking.",
                images: [
                    `${process.env.PUBLIC_URL}/person6 (2).jpg`
                ],
                perks: ["Homey", "Nice", "Talkative"],
            },
            {
                id: 11,
                title: "Ryan Sade",
                description: "Programmer with a passion for cats and cooking.",
                images: [
                    `${process.env.PUBLIC_URL}/person6 (3).jpg`
                ],
                perks: ["Homey", "Nice", "Talkative"],
            },
            {
                id: 12,
                title: "Sam Sloth",
                description: "Programmer with a passion for cats and cooking.",
                images: [
                    `${process.env.PUBLIC_URL}/person6 (4).jpg`
                ],
                perks: ["Homey", "Nice", "Talkative"],
            },
        {
            id: 5,
            title: "Mike Pence",
            description: "Programmer with a passion for cats and cooking.",
            images: [
                `${process.env.PUBLIC_URL}/person5 (1).jpg`
            ],
            perks: ["Homey", "Nice", "Talkative"],
        },
        {
            id: 6,
            title: "Taylor Beth",
            description: "Programmer with a passion for cats and cooking.",
            images: [
                `${process.env.PUBLIC_URL}/person5 (2).jpg`
            ],
            perks: ["Homey", "Nice", "Talkative"],
        },
        {
            id: 7,
            title: "Ryan Sade",
            description: "Programmer with a passion for cats and cooking.",
            images: [
                `${process.env.PUBLIC_URL}/person5 (3).jpg`
            ],
            perks: ["Homey", "Nice", "Talkative"],
        },
        {
            id: 8,
            title: "Sam Sloth",
            description: "Programmer with a passion for cats and cooking.",
            images: [
                `${process.env.PUBLIC_URL}/person5 (4).jpg`
            ],
            perks: ["Homey", "Nice", "Talkative"],
        },
    ];

    return (
        <>
            {listings.map((listing) => (
                <Col xs={12} md={6} lg={3} key={listing.id} className="mb-4">
                    <Card className="p-3">
                        {/* First image of the listing */}
                        <img
                            className="mb-3"
                            src={listing.images[0]}
                            alt={listing.title}
                            style={{ height: "10em", objectFit: "cover", backgroundColor: "#e0e0e0" }}
                        />
                        <Card.Body>
                            <Card.Title>{listing.title}</Card.Title>
                            <Card.Text>{listing.description}</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </>
    );
};

export default Person;
