import React from "react";

import { Button } from "react-bootstrap";

import { FaInbox } from "react-icons/fa";

const MessagesButton = () => {

    return (
        <a href="/messages" className="text-decoration-none">
        <Button variant="success" className="rounded-pill d-flex align-items-center px-4" style={{ width: '150px', margin:'4px' }}>
            <FaInbox className="me-2" /> Messages
        </Button>
        </a>
    );
    }

export default MessagesButton;