import React from "react";


const Messages = () => {
    return (
        
        <>
            Messages
        </>
    );
    }

export default Messages;