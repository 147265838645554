import React from 'react';
import { Container, Row, Col, Button, Card, Form, Navbar, Nav } from 'react-bootstrap';
import { FaSearch, FaHeart, FaArrowLeft, FaArrowRight, FaSlidersH } from 'react-icons/fa';
import './ComponentStyles/OdaiaStyles.css';
import Listing from './Listing.js';
import FeaturedListing from './FeaturedListing.js';
import FeaturedPerson from './FeaturedPerson.js';
import PromotedListingCarousel from './PromotedListingCarousel.js';
import { useListingsContext } from '../context/ListingsContext.js';
import { usePageContext } from "../context/PageContext";
import MapButton from './MapButton.js';
import BestMatchCarousel from './BestMatchCarousel.js';
import AddListingButton from './AddListingButton.js';
import MessagesButton from './MessageButton.js';
import FavoritesButton from './FavoritesButton.js';
import Person from './Person.js';


const AdZone = () => (
  <Row>
    <Col>
      <FeaturedListing />
    </Col>
    <Col>
      <FeaturedListing />
    </Col>
    <Col>
      <FeaturedListing />
    </Col>
  </Row>
);
const AdZonePerson = () => (
  <Row>
    <Col>
            <FeaturedPerson id={1} />
        </Col>
        <Col>
            <FeaturedPerson id={2} />
        </Col>
        <Col>
            <FeaturedPerson id={3} />
        </Col>
  </Row>
);

const Listings = () => {
  const { listings } = useListingsContext();
  const { sw, setSwitch } = usePageContext();

  return (
    <>
      {/* Header */}
      <Navbar bg="white" expand="lg" className="mb-4">
        <Container fluid>
          <Nav className="me-auto ms-auto">
            <div className="users-listings-switch-containter">
              <div className="background-switch" onClick={() => setSwitch(!sw)}>
                {/* Icons at each end */}
                <img
                  src="https://icons.veryicon.com/png/o/miscellaneous/font-awesome-1/users-91.png"
                  alt="U"
                  className="icon-left"
                />
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Font_Awesome_5_regular_building.svg/896px-Font_Awesome_5_regular_building.svg.png"
                  alt="L"
                  className="icon-right"
                />
                {/* The sliding switch circle */}
                <div className={`switch ${sw ? "switch-true" : ""}`}>
                  <div className="switch-circle">
                    <img
                      src={
                        sw
                          ? "https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Font_Awesome_5_regular_building.svg/896px-Font_Awesome_5_regular_building.svg.png"
                          : "https://icons.veryicon.com/png/o/miscellaneous/font-awesome-1/users-91.png"
                      }
                      alt={sw ? "L" : "U"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Form className="d-flex me-2">
              <Form.Control type="search" placeholder="Cauta" className="me-2" />
              <Button variant="outline-dark">
                <FaSearch />
              </Button>
            </Form>
            <Button variant="outline-dark">
              <FaSlidersH />
            </Button>
          </Nav>
        </Container>
      </Navbar>

      {sw ? (
        <Container fluid>
          <PromotedListingCarousel />
          <AdZone />
          <Row>
            {listings.map((list, index) => (
              <Listing listing={list} key={index} />
            ))}
          </Row>
        </Container>
      ) : (
        <Container fluid>
          <BestMatchCarousel />
          <AdZonePerson />
          <Row>
            {listings.map((list, index) => (
              <Person />
            ))}
          </Row>
        </Container>
      )}

      {/* Fixed Action Menu */}
      <Container
        fluid
        className="fixed-action-menu responsive-buttons"
      >
        <FavoritesButton />
        <MapButton />
        <AddListingButton />
        <MessagesButton />
      </Container>

      {/* Pagination */}
      <Container fluid>
        <Row className="d-flex justify-content-center">
          <Button
            variant="outline-dark"
            className="rounded-pill d-flex align-items-center px-4"
            style={{ width: '150px', margin: '4px' }}
          >
            <FaArrowLeft className="me-2" /> Previous
          </Button>
          <Button
            variant="outline-dark"
            className="rounded-pill d-flex align-items-center px-4"
            style={{ width: '150px', margin: '4px' }}
          >
            Next <FaArrowRight className="ms-2" />
          </Button>
        </Row>
      </Container>

      {/* Footer */}
      <Container fluid>
        <Row className="d-flex justify-content-center mt-4">
          <hr />
          <p className="text-muted">Odaia 2024</p>
        </Row>
      </Container>
    </>
  );
};

export default Listings;
