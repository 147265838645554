import React from "react";
import { Container, Form, Button, Card } from "react-bootstrap";
import { FaUser, FaLock } from "react-icons/fa";
import './ComponentStyles/OdaiaStyles.css'
import { useUserContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {

  const {login} = useUserContext();

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try{
      await login(e.target.formEmail.value, e.target.formPassword.value);
    navigate("/"); }
    catch(error){
      console.error("Login failed:", error);
      alert("Login failed. Please try again.");
    }
  }

  return (
    <Container
      className="d-flex justify-content-center align-items-center vh-100"
      style={{ backgroundColor: "#f8f9fa" }}
    >
      <Card style={{ width: "400px" }} className="shadow-sm border-0">
        <Card.Body>
          <h3 className="text-center mb-4" style={{ fontWeight: "bold" }}>
            Login
          </h3>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label>
                <FaUser className="me-2" />
                Email
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your email or username"
              />
            </Form.Group>

            <Form.Group controlId="formPassword" className="mb-4">
              <Form.Label>
                <FaLock className="me-2" />
                Password
              </Form.Label>
              <Form.Control type="password" placeholder="Enter your password" />
            </Form.Group>

            <div className="d-grid">
              <Button type="submit" className="rounded-pill btn btn-odaia">
                Login
              </Button>
            </div>
          </Form>

          <div className="text-center mt-4">
            <a href="/forgot-password" style={{color:"#3D5E41"}} className="text-decoration-none">
              Forgot Password?
            </a>
          </div>
          <div className="text-center mt-2">
            <a href="/register" className="text-decoration-none">
              <strong style={{color:"#3D5E41"}}>Sign Up</strong>
            </a>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default LoginPage;
