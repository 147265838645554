import React from 'react';
import { Button } from 'react-bootstrap';
import { FaMapMarkerAlt } from 'react-icons/fa';

const MapButton = () => {
  return (
    <div >
      <Button variant="success" className="rounded-pill d-flex align-items-center px-4" style={{ width: '100px', margin:'4px' }}>
        <FaMapMarkerAlt className="me-2" /> Map
      </Button>
    </div>
  );
};

export default MapButton;
