import React, { useRef, useState } from 'react';
import { Carousel, Card, Badge, Row, Col } from 'react-bootstrap';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import './ComponentStyles/OdaiaStyles.css';
import PromotedListing from './PromotedListing';

const PromotedListingCarousel = () => {
  const carouselRef = useRef(null);

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const [promotedListings, setPromotedListings] = useState([
    {
      id: 1,
      title: "Listing 1",
      description: "This is the description for listing 1.",
      price: 1200,
      maximumPeople: 4,
      nrRooms: 3,
      nrBedrooms: 2,
      nrBathrooms: 1,
      nrKitchens: 1,
      nrLivingrooms: 1,
      floor: 1,
      yearOfConstruction: 2000,
      surface: 100,
      compartmenting: "DETACHED",
      perks: [
        "PARKING",
        "BALCONY",
        "GARDEN"
      ],
      preferences: [
        "PET_FRIENDLY",
        "SMOKING_ALLOWED"
      ],
      images: [
        `${process.env.PUBLIC_URL}/listing1.jpeg`
      ]
    },
    {
      id: 2,
      title: "Listing 2",
      description: "This is the description for listing 2.",
      price: 1500,
      maximumPeople: 5,
      nrRooms: 4,
      nrBedrooms: 3,
      nrBathrooms: 2,
      nrKitchens: 1,
      nrLivingrooms: 1,
      floor: 2,
      yearOfConstruction: 2005,
      surface: 120,
      compartmenting: "SEMI_DETACHED",
      perks: [
        "PARKING",
        "BALCONY",
        "GARDEN"
      ],
      preferences: [
        "PET_FRIENDLY",
        "SMOKING_ALLOWED"
      ],
      images: [
        `${process.env.PUBLIC_URL}/listing2.jpeg`
      ]
    }
  ]);


  return (
    <div style={{ position: 'relative', padding: '0 60px', marginBottom: '2rem' }}>
      <Carousel
        indicators={false}
        controls={false}
        className="promoted-carousel"
        ref={carouselRef}  // Attach the ref here
      >
        <Carousel.Item>
          <Row className="justify-content-center">
            <PromotedListing listing={promotedListings[0]} />
            <PromotedListing listing={promotedListings[1]} />
            <PromotedListing listing={promotedListings[0]} />
          </Row>
        </Carousel.Item>

        <Carousel.Item>
          <Row className="justify-content-center">
            <PromotedListing listing={promotedListings[1]} />
            <PromotedListing listing={promotedListings[0]} />
            <PromotedListing listing={promotedListings[1]} />
          </Row>
        </Carousel.Item>
      </Carousel>

      <div className="carousel-controls">
        <span className="carousel-control-prev" role="button" onClick={handlePrev}>
          <FaArrowLeft style={{ fontSize: '1.5rem', color: '#333', position: 'absolute', left: '1em' }} />
        </span>
        <span className="carousel-control-next" role="button" onClick={handleNext}>
          <FaArrowRight style={{ fontSize: '1.5rem', color: '#333', position: 'absolute', right: '1em' }} />
        </span>
      </div>
    </div>
  );
};

export default PromotedListingCarousel;
