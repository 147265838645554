import { createContext, useState, useContext, useEffect } from "react";
import { redirect } from "react-router-dom";
import { API_POINT } from "../components/apiPoints/ApiPoints";

const UserContext = createContext();

const UserProvider = (props) => {

    const [user, setUser] = useState({ loggedIn: false });

    const login = async (email, password) => {
        try {
            console.log("Attempting login...");
            const response = await fetch(`${API_POINT}/auth/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ email, password }),
            });
    
            if (!response.ok) {
                throw new Error(`Login failed: ${response.status} ${response.statusText}`);
            }
    
            const data = await response.json();
            console.log("Server Response:", data);
    
            const userData = { ...data, loggedIn: true };
    
            console.log("Before setUser:", user);
            setUser(userData);
            localStorage.setItem("user", JSON.stringify(userData)); 
    
            console.log("After setUser (state update is async, so this will log old value):", user);
        } catch (error) {
            console.error("Error during login:", error);
        }
    };

    useEffect(() => {
        const savedUser = localStorage.getItem("user");
        if (savedUser) {
            setUser(JSON.parse(savedUser)); // Set the user state if it exists in localStorage
        }
    }, []);

    useEffect(() => {
        console.log("User state updated:", user);
    }, [user]);

    const register = async (email, password, firstName, lastName, role) => {
        console.log("register");

        console.log({ email, password, firstName, lastName, role });

        await fetch(`${API_POINT}/auth/register`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: email,
                password: password,
                firstname: firstName,
                lastname: lastName,
                accessPreferences: role
            })
        }).then(response => response.json()).then(data => {
            alert(data.message);
            //redirect to login page
            
        }).catch(error => {
            console.log(error);
        });
    }

    const logout = () => {
        console.log("logout");
        setUser({ loggedIn: false });
        localStorage.removeItem("user");
    }

    return (
        <UserContext.Provider value={{ user, setUser, login, logout, register }}>
            {props.children}
        </UserContext.Provider>
    );

}

export const useUserContext = () => {
    return useContext(UserContext);
}

export default UserProvider;