import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useListingsContext } from '../context/ListingsContext';
import { Card, Col, Row, ListGroup, Badge, Button } from 'react-bootstrap'; // Import Bootstrap components
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa'; // Import icons
import './ComponentStyles/OdaiaStyles.css';

const ListingDetails = () => {
  const { listings } = useListingsContext(); // Access the listings from context
  const { id } = useParams(); // Get the listing ID from the URL
  const listing = listings.find(listing => listing.id === Number(id)); // Find the matching listing

  const [likes, setLikes] = useState(0); // State to handle likes
  const [dislikes, setDislikes] = useState(0); // State to handle dislikes

  if (!listing) {
    return <div>Listing not found</div>; // Handle case if listing doesn't exist
  }

  // Handlers for like and dislike buttons
  const handleLike = () => setLikes(likes + 1);
  const handleDislike = () => setDislikes(dislikes + 1);

  return (
    <Row className="justify-content-center mt-4">
      <Col md={8}>
        <Card>
          <Row className="g-0">
            <Col md={6}>
              {/* Display main image of the listing */}
              <Card.Img 
                variant="top" 
                src={listing.images[0]} 
                alt={listing.title} 
                style={{ height: '100%', objectFit: 'cover' }} 
              />
            </Col>
            <Col md={6}>
              <Card.Body>
                {/* Title and Price */}
                <Card.Title className="fw-bold">{listing.title}</Card.Title>
                <h4 style={{color:'#32743a'}}>${listing.price}</h4>

                {/* Description */}
                <Card.Text>{listing.description}</Card.Text>

                {/* Details like rooms, surface, year of construction */}
                <ListGroup variant="flush">
                  <ListGroup.Item><strong>Maximum People:</strong> {listing.maximumPeople}</ListGroup.Item>
                  <ListGroup.Item><strong>Rooms:</strong> {listing.nrRooms}</ListGroup.Item>
                  <ListGroup.Item><strong>Bedrooms:</strong> {listing.nrBedrooms}</ListGroup.Item>
                  <ListGroup.Item><strong>Bathrooms:</strong> {listing.nrBathrooms}</ListGroup.Item>
                  <ListGroup.Item><strong>Kitchens:</strong> {listing.nrKitchens}</ListGroup.Item>
                  <ListGroup.Item><strong>Living rooms:</strong> {listing.nrLivingrooms}</ListGroup.Item>
                  <ListGroup.Item><strong>Floor:</strong> {listing.floor}</ListGroup.Item>
                  <ListGroup.Item><strong>Year of Construction:</strong> {listing.yearOfConstruction}</ListGroup.Item>
                  <ListGroup.Item><strong>Surface:</strong> {listing.surface} sqm</ListGroup.Item>
                  <ListGroup.Item><strong>Compartmenting:</strong> {listing.compartmenting}</ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Col>
          </Row>

          <Card.Body>
            {/* Perks and Preferences */}
            <Row>
              <Col md={6}>
                <h5>Perks:</h5>
                <ul>
                  {listing.perks.map(perk => (
                    <li key={perk}>
                      <Badge bg="success" className="me-1">{perk}</Badge>
                    </li>
                  ))}
                </ul>
              </Col>
              <Col md={6}>
                <h5>Preferences:</h5>
                <ul>
                  {listing.preferences.map(preference => (
                    <li key={preference}>
                      <Badge bg="info" className="me-1">{preference}</Badge>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>

            {/* Additional Images */}
            <Row>
              {listing.images.slice(1).map((image, index) => (
                <Col key={index} md={4} className="mb-3">
                  <Card.Img 
                    src={image} 
                    alt={`Listing image ${index + 1}`} 
                    style={{ height: '150px', objectFit: 'cover' }} 
                  />
                </Col>
              ))}
            </Row>
          </Card.Body>

          {/* Footer with Like/Dislike and Contact Owner button */}
          <Card.Footer className="d-flex justify-content-between align-items-center">
            <div>
              {/* Like and Dislike buttons */}
              <Button variant="outline-success" onClick={handleLike} className="me-2">
                <FaThumbsUp /> Like ({likes})
              </Button>
              <Button variant="outline-danger" onClick={handleDislike}>
                <FaThumbsDown /> Dislike ({dislikes})
              </Button>
            </div>
            
            {/* Contact Owner button */}
            <Button variant="primary" className='btn-odaia'>
              Contact Owner
            </Button>
          </Card.Footer>
        </Card>
      </Col>
    </Row>
  );
};

export default ListingDetails;
