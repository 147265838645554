import React, { useRef } from 'react';
import { Carousel, Card, Row, Col } from 'react-bootstrap';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

import './ComponentStyles/OdaiaStyles.css';

const NestedPhotoCarousel = ({ photos }) => (
    <Carousel indicators={false} controls={true} className="nested-carousel">
        {photos.map((photo, index) => (
            <Carousel.Item key={index}>
                <img
                    src={photo}
                    alt={`Slide ${index}`}
                    style={{ height: 'fit', width: '100%', objectFit: 'cover' }}
                />
            </Carousel.Item>
        ))}
    </Carousel>
);

export default NestedPhotoCarousel;