import React, { useEffect, useState } from "react";
import { useUserContext } from "../context/UserContext";
import { Card, Row, Col, Container } from "react-bootstrap";
import { FaUserCircle, FaEnvelope, FaCalendarAlt } from "react-icons/fa";
import { API_POINT } from "./apiPoints/ApiPoints";

const Profile = () => {
    const { user } = useUserContext();
    const [userInfo, setUserInfo] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await fetch(`${API_POINT}/users/${user.id}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setUserInfo(data);
                } else {
                    console.error("Failed to fetch user info:", response.statusText);
                }
            } catch (error) {
                console.error("Error fetching user info:", error);
            } finally {
                setLoading(false);
            }
        };

        if (user && user.id) {
            fetchUserInfo();
        }
    }, [user]);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <h3>Loading...</h3>
            </div>
        );
    }

    if (!userInfo) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <h3>No User Info Available</h3>
            </div>
        );
    }

    return (
        <Container className="py-5">
            <Row className="justify-content-center">
                <Col md={6}>
                    <Card className="shadow-lg">
                        <Card.Body>
                            <div className="text-center mb-4">
                                <FaUserCircle size={80} className="text-primary" />
                            </div>
                            <Card.Title className="text-center mb-4">
                                {userInfo.firstName} {userInfo.lastName}
                            </Card.Title>
                            <Card.Text>
                                <Row className="mb-3">
                                    <Col xs={2} className="text-end">
                                        <FaEnvelope />
                                    </Col>
                                    <Col>{userInfo.email || "N/A"}</Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col xs={2} className="text-end">
                                        <FaCalendarAlt />
                                    </Col>
                                    <Col>Joined: {new Date(userInfo.createdAt).toLocaleDateString() || "N/A"}</Col>
                                </Row>
                            </Card.Text>
                            <div className="text-center">
                                <p className="text-muted">User Preferences:</p>
                                <ul className="list-unstyled">
                                    {userInfo.accessPreferences?.length ? (
                                        userInfo.accessPreferences.map((pref, index) => (
                                            <li key={index}>{pref}</li>
                                        ))
                                    ) : (
                                        <li>No preferences set.</li>
                                    )}
                                </ul>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Profile;
