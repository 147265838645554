import React from "react";
import { useParams, Link } from "react-router-dom"; // Import Link
import { Col, Card } from "react-bootstrap";
import { FaStar } from "react-icons/fa"; // Import star icon
import './ComponentStyles/OdaiaStyles.css';

const PromotedListing = ({ listing }) => {
  console.log(listing);
  const listing_id = Number(useParams().id);

  return (
    <Col className="d-flex justify-content-center">
      {/* Wrap the card with a Link component */}
      <Link to={`/listing/${listing?.id}`} style={{ textDecoration: 'none', color: 'inherit' }}> 
        <Card className="mb-3" style={{ width: '250px' }}>
          <div style={{ position: 'relative' }}>
            <img
              className="mb-3"
              src={listing?.images[0]}
              style={{ height: '10em', backgroundColor: '#e0e0e0' }}
              alt={listing?.title} // Add alt text for accessibility
            />
            <div
              className="badge-odaia rounded"
              style={{
                position: 'absolute',
                top: 10,
                left: 10,
                fontSize: 12,
              }}
            >
              <div className="m-1">Anunt Promovat</div>
            </div>
          </div>
          <Card.Body>
            <Card.Text className="fw-bold" style={{ fontSize: 12 }}>
              {listing?.title}
            </Card.Text>
            <Card.Text style={{ fontSize: 12 }}>{listing?.description}</Card.Text>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-between align-items-center">
            <div>
              {[...Array(5)].map((_, index) => (
                <FaStar
                  key={index}
                  color={index < 4 ? "#ffc107" : "#e4e5e9"}
                  size={14}
                />
              ))}
            </div>
            <div className="fw-bold" style={{ fontSize: 14 }}>
              {listing?.price}
            </div>
          </Card.Footer>
        </Card>
      </Link>
    </Col>
  );
};

export default PromotedListing;
